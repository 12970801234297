import './ConfirmationModal.scss';

import React, { useEffect, useRef, useState } from 'react';
import { FlightButton, FlightTextInput, getIcon } from '@flybits/design-system';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';
import { ConfirmationModalActionStyles, ConfirmationModalProps, ConfirmationModalTypes } from '../shared.types';

const CLASS_MAIN = 'confirmation-modal';
const CLASSES = {
  DELETE_MODAL: `${CLASS_MAIN}--delete`,
  CHILDREN: `${CLASS_MAIN}__children`,
  CLOSE_ICON: `${CLASS_MAIN}__children__close-icon`,
  BODY: `${CLASS_MAIN}__children__body`,
  ICON: `${CLASS_MAIN}__children__body__icon`,
  CONTAINER: `${CLASS_MAIN}__children__body__container`,
  INFO: `${CLASS_MAIN}__children__body__container__info`,
  TITLE: `${CLASS_MAIN}__children__body__container__info__title`,
  DESCRIPTION: `${CLASS_MAIN}__children__body__container__info__description`,
  DELETE_MODAL_DESCRIPTION: `${CLASS_MAIN}__children__body__container__info__description--delete`,
  WARNING_BANNER: `${CLASS_MAIN}__children__body__container__warning-banner`,
  WARNING_ICON: `${CLASS_MAIN}__children__body__container__warning-banner__icon`,
  WARNING_INFO: `${CLASS_MAIN}__children__body__container__warning-banner__info`,
  WARNING_TITLE: `${CLASS_MAIN}__children__body__container__warning-banner__info__title`,
  WARNING_DESC: `${CLASS_MAIN}__children__body__container__warning-banner__info__desc`,
  CONFIRMATION_INPUT: `${CLASS_MAIN}__children__body__container__confirmation-input`,
  CONFIRMATION_LABEL: `${CLASS_MAIN}__children__body__container__confirmation-input__label`,
  ACTIONS: `${CLASS_MAIN}__children__body__container__actions`,
  ACTIONS_BUTTON: `${CLASS_MAIN}__children__body__container__actions__button`,
  ACTIONS_DELETE: `${CLASS_MAIN}__children__body__container__actions-delete`,
  ACTIONS_DELETE_COLUMN: `${CLASS_MAIN}__children__body__container__actions-delete--column`,
  ACTIONS_DELETE_BUTTON: `${CLASS_MAIN}__children__body__container__actions-delete__button`,
  LOADING_CONTAINER: `${CLASS_MAIN}__loading-container`,
  LOADING_SPINNER: `${CLASS_MAIN}__loading-container__spinner`,
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  closeModal,
  theme,
  icon,
  title,
  description,
  primaryAction,
  secondaryAction,
  isLoading,
  confirmationInput: {
    placeholderText: confirmationPlaceholderText,
    matchText: confirmationMatchText,
    label: confirmationInputLabel = '',
  } = {},
  className = '',
  actionStyle = '',
  warningMessage: warningDescription = '',
}) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const [confirmInputVal, setConfirmInputVal] = useState('');
  const [confirmDisabled, setConfirmDisabled] = useState(true);

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
  }, [isOpen]);

  return (
    <dialog
      ref={dialogRef}
      onCancel={closeModal}
      className={`${CLASS_MAIN} ${theme === ConfirmationModalTypes.DELETE ? CLASSES.DELETE_MODAL : ''} ${className}`}
    >
      {isLoading && (
        <div className={CLASSES.LOADING_CONTAINER}>
          <div className={CLASSES.LOADING_SPINNER}>
            <LoadingIcon />
          </div>
        </div>
      )}
      <div className={CLASSES.CHILDREN}>
        <div className={CLASSES.CLOSE_ICON}>
          <button onClick={closeModal}>{getIcon('clear', {})}</button>
        </div>
        <div className={CLASSES.BODY}>
          <div className={CLASSES.ICON}>{icon}</div>
          <div className={CLASSES.CONTAINER}>
            <div className={CLASSES.INFO}>
              <div className={CLASSES.TITLE}>{title}</div>
              <div
                className={`${CLASSES.DESCRIPTION} ${
                  theme === ConfirmationModalTypes.DELETE ? CLASSES.DELETE_MODAL_DESCRIPTION : ''
                }`}
              >
                {description}
              </div>
            </div>
            {warningDescription && (
              <div className={CLASSES.WARNING_BANNER}>
                <div className={CLASSES.WARNING_ICON}>{getIcon('warning', {})}</div>
                <div className={CLASSES.WARNING_INFO}>
                  <div className={CLASSES.WARNING_TITLE}>Warning</div>
                  <div className={CLASSES.WARNING_DESC}>{warningDescription}</div>
                </div>
              </div>
            )}
            {confirmationMatchText && (
              <div className={CLASSES.CONFIRMATION_INPUT}>
                {confirmationInputLabel && <div className={CLASSES.CONFIRMATION_LABEL}>{confirmationInputLabel}</div>}
                <FlightTextInput
                  name="merchant name"
                  width="100%"
                  value={confirmInputVal}
                  placeholderText={confirmationPlaceholderText || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const inputVal = e.target.value || '';
                    setConfirmInputVal(inputVal);
                    setConfirmDisabled(inputVal !== confirmationMatchText);
                  }}
                />
              </div>
            )}
            {theme === ConfirmationModalTypes.DELETE ? (
              <div
                className={`${CLASSES.ACTIONS_DELETE} ${
                  actionStyle === ConfirmationModalActionStyles.COLUMN ? CLASSES.ACTIONS_DELETE_COLUMN : ''
                }`}
              >
                <div className={`${CLASSES.ACTIONS_DELETE_BUTTON} ${CLASSES.ACTIONS_DELETE_BUTTON}--secondary`}>
                  <FlightButton
                    label={secondaryAction.value}
                    ariaLabel={secondaryAction.value}
                    onClick={secondaryAction.onClickHandler}
                    theme={'secondary'}
                  />
                </div>
                <div className={`${CLASSES.ACTIONS_DELETE_BUTTON} ${CLASSES.ACTIONS_DELETE_BUTTON}--primary`}>
                  <FlightButton
                    label={primaryAction.value}
                    ariaLabel={primaryAction.value}
                    onClick={primaryAction.onClickHandler}
                    theme={'primary'}
                    disabled={confirmationMatchText && confirmDisabled}
                  />
                </div>
              </div>
            ) : (
              <div className={CLASSES.ACTIONS}>
                <div className={`${CLASSES.ACTIONS_BUTTON} ${CLASSES.ACTIONS_BUTTON}--primary`}>
                  <FlightButton
                    label={primaryAction.value}
                    ariaLabel={primaryAction.value}
                    onClick={primaryAction.onClickHandler}
                    theme={theme === ConfirmationModalTypes.PUBLISH ? 'primary' : 'minor'}
                    disabled={confirmationMatchText && confirmDisabled}
                  />
                </div>
                <div className={`${CLASSES.ACTIONS_BUTTON} ${CLASSES.ACTIONS_BUTTON}--secondary`}>
                  <FlightButton
                    label={secondaryAction.value}
                    ariaLabel={secondaryAction.value}
                    onClick={secondaryAction.onClickHandler}
                    theme={theme === ConfirmationModalTypes.DISCARD ? 'secondary' : 'minor'}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default ConfirmationModal;
