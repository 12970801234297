import './Graphs.scss';
import React from 'react';
import { FlightTable } from '@flybits/design-system';

const CLASS_TABLE_GRAPH = 'table-graph';
const CLASSES = {
  VALUE: `${CLASS_TABLE_GRAPH}__value`,
};

type TableGraphHeader = { key: string; name: string };
interface TableGraphProps extends React.ComponentProps<'div'> {
  headers: Array<TableGraphHeader>;
  responseData: any;
}

const TableGraph: React.FC<TableGraphProps> = ({ headers, responseData }) => {
  const tableHeaders = headers.map((header) => ({
    key: header.key,
    name: header.name,
    hideTooltip: true,
    isVisible: true,
    isSortable: false,
  }));

  const tableData = responseData.data?.map((item: any) => {
    const itemData: any = {};

    headers.map((header: TableGraphHeader) => {
      itemData.key = item.journey_id;
      const value = item[header.key];

      if (typeof value === 'number') {
        itemData[header.key as keyof TableGraphHeader] = (
          <span className={CLASSES.VALUE}>{item[header.key].toLocaleString()}</span>
        );
      } else {
        itemData[header.key as keyof TableGraphHeader] = item[header.key];
      }
    });

    return itemData;
  });

  return (
    <div style={{ overflowY: 'auto', height: '243px' }}>
      <FlightTable
        className={CLASS_TABLE_GRAPH}
        isLoading={false}
        tableHeaders={tableHeaders}
        tableData={tableData}
        hasPaginationAfterTable={false}
        hasPaginationBeforeTable={false}
      />
    </div>
  );
};

export default TableGraph;
