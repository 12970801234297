import { createContext } from 'react';

import { Zone, Module, ZoneConfig } from 'pages/Zones/types';
import { ZoneErrorMessages } from 'components/Zones/v2/ZoneAttributes/ZoneAttributes';
import { ModuleInfoErrorMessages } from 'components/Zones/v2/ModuleInfo/ModuleInfo';
import { ModuleLayoutErrorMessages } from 'components/Zones/v2/ModuleLayout/ModuleLayout';

export const initialZone: Zone = {
  id: '',
  name: '',
  modules: [],
  labels: [],
  referenceID: '',
};

export const initialZoneErrorMessages: ZoneErrorMessages = {
  name: '',
  refId: '',
  labels: '',
};

export type ModuleErrorMessages = ModuleInfoErrorMessages & ModuleLayoutErrorMessages;

export const initialModuleErrorMessages: ModuleErrorMessages = {
  name: '',
  height: '',
  labels: '',
  layoutHTML: '',
  styleCSS: '',
  layout: '',
};

export type PublishChangesBannerInfo = {
  title?: string;
  errorMessage?: string;
  show: boolean;
  didAnimationEnd: boolean;
};

export type ContentPrioritizationInfo = {
  show: boolean;
  contentId?: string;
  moduleId?: string;
  openPinnedTab?: boolean;
  hideContentHeader?: boolean;
};

export const initialPublishChangesBannerInfo: PublishChangesBannerInfo = {
  show: false,
  title: '',
  errorMessage: '',
  didAnimationEnd: false,
};

// context state subject to change based on ease of use
export type TPrioritizationContext = {
  // slideouts and onboarding
  isZoneConfigLoading: boolean;
  selectedZone: Partial<Zone>;
  setSelectedZone: React.Dispatch<React.SetStateAction<Partial<Zone>>>;
  zoneErrorMessages: ZoneErrorMessages;
  setZoneErrorMessages: React.Dispatch<React.SetStateAction<ZoneErrorMessages>>;
  selectedModule: Partial<Module> | null;
  setSelectedModule: React.Dispatch<React.SetStateAction<Partial<Module>>>;
  moduleErrorMessages: ModuleInfoErrorMessages & ModuleLayoutErrorMessages;
  setModuleErrorMessages: React.Dispatch<React.SetStateAction<ModuleInfoErrorMessages & ModuleLayoutErrorMessages>>;
  addZone: (zone: Zone) => Zone;
  removeZone: (zone: Zone) => void;
  updateZone: (zone: Zone) => void;
  addModule: (zoneId: string, updatedModule: Module) => void;
  removeModule: (zoneId: string, module: Module) => void;
  updateModule: (zoneId: string, updatedModule: Module) => void;
  labelAndOrderedContentSelectedModuleId: string | null;
  setLabelAndOrderedContentSelectedModuleId: React.Dispatch<React.SetStateAction<string | null>>;
  showModuleDetailsSlideout: boolean;
  setShowModuleDetailsSlideout: React.Dispatch<React.SetStateAction<boolean>>;
  showContentManagerSlideout: ContentPrioritizationInfo;
  setShowContentManagerSlideout: React.Dispatch<React.SetStateAction<ContentPrioritizationInfo>>;
  isCustomLayout: boolean;
  setCustomLayout: React.Dispatch<React.SetStateAction<boolean>>;

  // back-end state
  backEndZoneConfigs?: ZoneConfig;
  // current state
  zones?: Zone[];
  setZones: React.Dispatch<React.SetStateAction<Zone[]>>;
  resetZoneConfigs: () => void;
  publishZoneConfigs: (zonesOverride?: Zone[]) => Promise<ZoneConfig>;
  queryKey: string;

  // UI state
  isLabelManagementOpen: boolean;
  setLabelManagementOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isPinnedContentScheduleOpen: boolean;
  setPinnedContentScheduleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  resetSelectedZone: () => void;

  // Publish Changes Banner
  publishChangesBannerInfo: PublishChangesBannerInfo;
  setPublishChangesBannerInfo: React.Dispatch<React.SetStateAction<PublishChangesBannerInfo>>;
  numUnsavedChanges: number;
};

const PrioritizationContextDefaults: TPrioritizationContext = {
  isZoneConfigLoading: false,
  selectedZone: {},
  setSelectedZone: () => null,
  zoneErrorMessages: initialZoneErrorMessages,
  setZoneErrorMessages: () => null,
  selectedModule: null,
  setSelectedModule: () => null,
  labelAndOrderedContentSelectedModuleId: null,
  setLabelAndOrderedContentSelectedModuleId: () => null,
  moduleErrorMessages: initialModuleErrorMessages,
  setModuleErrorMessages: () => null,
  setZones: () => null,
  publishZoneConfigs: async () => ({ id: '', zones: [] }),
  resetZoneConfigs: () => null,
  queryKey: '',
  addZone: () => initialZone,
  updateZone: () => null,
  removeZone: () => null,
  removeModule: () => null,
  isLabelManagementOpen: false,
  setLabelManagementOpen: () => null,
  isPinnedContentScheduleOpen: false,
  setPinnedContentScheduleOpen: () => null,
  addModule: () => null,
  updateModule: () => null,
  resetSelectedZone: () => null,
  publishChangesBannerInfo: initialPublishChangesBannerInfo,
  setPublishChangesBannerInfo: () => null,
  numUnsavedChanges: 0,
  showModuleDetailsSlideout: false,
  setShowModuleDetailsSlideout: () => null,
  showContentManagerSlideout: { show: false, hideContentHeader: false },
  setShowContentManagerSlideout: () => null,
  isCustomLayout: false,
  setCustomLayout: () => null,
};

export default createContext<TPrioritizationContext>(PrioritizationContextDefaults);
