import React, { useState } from 'react';
import './MetricsSelector.scss';
import { FlightSelect } from '@flybits/design-system';
import { METRIC_OPTIONS } from '../constants';

const MAIN_CLASS = 'metrics-selector';
const CLASSES = {
  LABEL: `${MAIN_CLASS}__label`,
  DD: `${MAIN_CLASS}__select`,
};

type MetricOption = Omit<(typeof METRIC_OPTIONS)[number], 'value'> & {
  value?: (typeof METRIC_OPTIONS)[number]['value'];
};
type MetricValue = MetricOption['value'];
type TMetricsSelectorProps = {
  selectedMetric?: MetricValue;
  onChange?: (metric?: MetricValue) => void;
};

export default function MetricsSelector({ onChange, selectedMetric }: TMetricsSelectorProps) {
  // replace state with props; find function subject to change
  const [selectedOption, setSelectedOption] = useState<MetricOption>(() => {
    let retVal: MetricOption = {
      key: 999,
      name: 'None Selected',
      value: undefined,
    };
    if (selectedMetric)
      retVal =
        METRIC_OPTIONS.find(
          (o) => o.value.entity === selectedMetric.entity && o.value.metric === selectedMetric.metric,
        ) || retVal;
    return retVal;
  });
  return (
    <section className={MAIN_CLASS}>
      <label className={CLASSES.LABEL} htmlFor="select-interval">
        Select a metric
      </label>
      <FlightSelect
        label=""
        className={`${CLASSES.DD}`}
        options={METRIC_OPTIONS}
        selected={selectedOption}
        width={'100%'}
        handleOptionClick={(option: (typeof METRIC_OPTIONS)[number]) => {
          setSelectedOption(option);
          onChange && onChange(option.value);
        }}
      />
    </section>
  );
}
