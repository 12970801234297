import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { TBarGraph } from 'pages/AnalyticsV2/types';

type TBarGraphProps = {
  responseData: TBarGraph;
};

const BarGraph: React.FC<TBarGraphProps> = ({ responseData }) => {
  const { title, xAxis, yAxis } = responseData.moduleConfig.chartConfig;
  return (
    <div style={{ height: '243px' }}>
      <ResponsiveBar
        label={title}
        data={responseData.data}
        keys={['value']}
        indexBy="name"
        margin={{ top: 10, right: 10, bottom: 40, left: 50 }}
        padding={0.2}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        // can set our own pool of colors if we want
        colors={'#0851df'}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 5,
          // inverted for now, as these went horizontal before
          legend: yAxis,
          legendPosition: 'middle',
          legendOffset: 32,
          truncateTickAt: 8,
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          // inverted for now, as these went horizontal before
          legend: xAxis,
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        animate={true}
      />
    </div>
  );
};

export default BarGraph;
