import React, { useState } from 'react';
import { FlightRadioButton } from '@flybits/design-system';
import { VISUALIZER_OPTIONS } from 'pages/AnalyticsV2/ExperiencesDashboard/CreateOrEditChart/constants';
import BarIcon from 'assets/images/bar-icon-v2.svg';
import TableIcon from 'assets/images/table-icon.svg';
import LineIcon from 'assets/images/single-line-graph-icon.svg';
import './VisualizationSelector.scss';

const visualizerOptions = [
  { key: VISUALIZER_OPTIONS.BAR, label: 'Bar', value: VISUALIZER_OPTIONS.BAR, iconSrc: BarIcon, alt: 'Bar Icon' },
  {
    key: VISUALIZER_OPTIONS.TABLE,
    label: 'Table',
    value: VISUALIZER_OPTIONS.TABLE,
    iconSrc: TableIcon,
    alt: 'Table Icon',
  },
  { key: VISUALIZER_OPTIONS.LINE, label: 'Line', value: VISUALIZER_OPTIONS.LINE, iconSrc: LineIcon, alt: 'Line Icon' },
];

const MAIN_CLASS = 'visualization-selector';
const CLASSES = {
  OPTION_CONTAINER: `${MAIN_CLASS}__radio__container`,
  OPTION_IMAGE: `${MAIN_CLASS}__radio__icon`,
  OPTION_RADIO: `${MAIN_CLASS}__radio__radio`,
};

type TVisualizationSelectionProps = {
  onChange?: (graphType: VISUALIZER_OPTIONS) => void;
  selectedVisualization?: VISUALIZER_OPTIONS;
};

export default function VisualizationSelector({ onChange, selectedVisualization }: TVisualizationSelectionProps) {
  // replace state with props
  const [selectedOption, setSelectedOption] = useState<VISUALIZER_OPTIONS | null>(selectedVisualization ?? null);

  const handleSelect = (key: VISUALIZER_OPTIONS) => {
    if (selectedOption === key) return;
    setSelectedOption(key);
    onChange && onChange(key);
  };

  return (
    <section className={MAIN_CLASS}>
      {visualizerOptions.map((visualizer, idx) => {
        const { key, label, value, iconSrc, alt } = visualizer;
        return (
          <div key={`ca-v2-visualizer-${idx}`} className={CLASSES.OPTION_CONTAINER}>
            <button className={CLASSES.OPTION_IMAGE} onClick={() => handleSelect(key)} aria-label={alt}>
              <img src={iconSrc} alt={alt} />
            </button>
            <FlightRadioButton
              key={key}
              className={CLASSES.OPTION_RADIO}
              label={label}
              onSelect={handleSelect}
              value={value}
              checked={selectedOption === value}
            />
          </div>
        );
      })}
    </section>
  );
}
