import React from 'react';
import { ReactComponent as AddGraphImage } from 'assets/images/experience-analytics.svg';
import './ExperienceDashboard.scss';
import { FlightButton } from '@flybits/design-system';
import { useHistory, useParams } from 'react-router-dom';
import ExperienceTile from './ExperienceTile';
import BarGraph from './Graphs/BarGraph';
import LineGraph from './Graphs/LineGraph';
import BarDataMock from './mocks/bar-graph-data.json';
import LineDataMock from './mocks/line-graph-data.json';
import TableDataMock from './mocks/table-graph.json';
import TableGraph from 'pages/AnalyticsV2/Experience/Graphs/TableGraph';

export type TAnalyticsRouteParams = {
  pid: string;
};

// CLASSES
const CLASS_MAIN = 'experience-dashboard';
const CLASSES = {
  BODY: `${CLASS_MAIN}__body`,
  HEADER: `${CLASS_MAIN}__header`,
  GRID: `${CLASS_MAIN}__grid`,
  TILE: `${CLASS_MAIN}__tile`,
  TILE_ADD_CHART: `${CLASS_MAIN}__tile__add-chart`,
  CHART: `${CLASS_MAIN}__chart`,
  ADD_CHART: `${CLASS_MAIN}__add-chart`,
};

function ExperienceDashboard() {
  const history = useHistory();
  const { pid } = useParams<TAnalyticsRouteParams>();

  return (
    <main className={CLASSES.BODY}>
      <h3 className={CLASSES.HEADER}>Experiences</h3>
      <div className={CLASSES.GRID}>
        <div className={CLASSES.TILE}>
          <ExperienceTile
            title="This Tile 1 has a very long title, it is huge, great title, it will certain overflow for sure!"
            description="The Tile 1 description is also very long, so long that it will overflow so I need to keep adding characters until I'm certain it had overflow"
            graph={<BarGraph responseData={BarDataMock} />}
          />
        </div>
        <div className={CLASSES.TILE}>
          <ExperienceTile
            title="Tile 2"
            description="Tile 2 description"
            graph={<LineGraph responseData={LineDataMock} />}
          />
        </div>
        <div className={CLASSES.TILE}>
          <ExperienceTile
            title="Push reach over the past 7 days"
            description="Based on experience name"
            graph={
              <TableGraph
                headers={[
                  { key: 'name', name: 'Experience' },
                  { key: 'value', name: 'Reach' },
                ]}
                responseData={TableDataMock}
              />
            }
          />
        </div>
        <div className={CLASSES.TILE}>
          <ExperienceTile title="Tile 4" graph={null} />
        </div>
        <div className={CLASSES.TILE_ADD_CHART}>
          <div className={CLASSES.ADD_CHART}>
            <AddGraphImage />
            <h6>Experience Analytics</h6>
            <p>Track metrics across different experiences created to see how they&apos;re performing.</p>
            <FlightButton
              label="Add Chart"
              iconLeft="add"
              size="small"
              onClick={() => history.push(`/project/${pid}/analytics/v2/experiences/create`)}
            />
          </div>
        </div>
      </div>
    </main>
  );
}

export default ExperienceDashboard;
