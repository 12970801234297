import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as LanguageIcon } from 'assets/icons/language-v2.svg';
import { FlightTooltip, getIcon } from '@flybits/design-system';
import useSettings from 'hooks/useSetting';
import { useThunkDispatch as useDispatch } from 'hooks/reduxHooks';
import { Language } from 'interface/settings/settings.interface';
import './LocalizationSelector.scss';

const MAIN_CLASS = 'localization-selector';
const CLASSES = {
  TRIGGER: `${MAIN_CLASS}__trigger`,
  LABEL: `${MAIN_CLASS}__label`,
  ICON: `${MAIN_CLASS}__icon`,
  INFO_ICON: `${MAIN_CLASS}__info`,
  CONTENT: `${MAIN_CLASS}__content`,
  DD_TRIGGER_SELECTED: `${MAIN_CLASS}__content__dd__language`,
  DD_ARROW: `${MAIN_CLASS}__arrow`,
  DD: `${MAIN_CLASS}__dropdown`,
  DD_BUTTON: `${MAIN_CLASS}__dropdown__button`,
};
type TLocalizationSelectorProps = {
  selectedLanguage?: string;
  label?: string;
  tooltip?: string;
  onChange?: (lang: string) => void;
};

const getSelectedlanguage = (languages: Language[], selectedLanguageId?: string) => {
  const selectedLang = languages.find((lang) => lang.id === selectedLanguageId);
  const defaultLang = languages.find((lang) => lang.isDefault);
  if (selectedLang) {
    return {
      id: selectedLang.id,
      text: `${selectedLang.name.toUpperCase()}${selectedLang.isDefault ? ' (default)' : ''}`,
    };
  } else if (defaultLang) {
    return { id: defaultLang.id, text: `${defaultLang.name.toUpperCase()} (default)` };
  } else if (languages.length > 0) {
    return { id: languages[0].id, text: `${languages[0].name.toUpperCase()} (default)` };
  } else {
    return { id: 'en', text: 'ENGLISH (default)' };
  }
};

const LocalizationSelector: React.FC<TLocalizationSelectorProps> = ({ selectedLanguage, label, tooltip, onChange }) => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const [dropDownOpen, setDropdownOpen] = useState(false);
  const { languages } = useSettings();
  const displayedLanguage = getSelectedlanguage(languages, selectedLanguage);

  const selectLanguage = (lang: Language) => {
    setDropdownOpen(false);
    if (selectedLanguage && lang.id === selectedLanguage) return;
    if (onChange) {
      onChange(lang.id);
      return;
    }
    dispatch({
      type: 'SET_PREVIEW_LANGUAGE',
      payload: { previewTextDirection: lang.direction || 'ltr', previewTextLanguage: lang.id },
    });
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return (
    <div className={MAIN_CLASS} ref={ref}>
      <button className={CLASSES.TRIGGER} onClick={() => setDropdownOpen((prev) => !prev)}>
        <div className={CLASSES.LABEL}>
          <LanguageIcon className={CLASSES.ICON} />
          {label ?? 'Preview Language'}
          <FlightTooltip
            isEnabled={!dropDownOpen}
            description={tooltip ?? 'Change preview language'}
            tabIndex={dropDownOpen ? -1 : 0}
          >
            {getIcon('infoFilled', { className: CLASSES.INFO_ICON })}
          </FlightTooltip>
        </div>
        <div className={CLASSES.CONTENT}>
          {displayedLanguage.text}
          {getIcon('baselineKeyboardArrowDown', {
            className: `${CLASSES.DD_ARROW} ${dropDownOpen ? `${CLASSES.DD_ARROW}--up` : ''}`,
          })}
        </div>
      </button>
      {dropDownOpen && (
        <div className={CLASSES.DD}>
          {languages.map((lang) => (
            <button
              key={`journey-preview-lang-v2-${lang.id}`}
              className={`${CLASSES.DD_BUTTON} ${lang.id === selectedLanguage ? `${CLASSES.DD_BUTTON}--selected` : ''}`}
              onClick={() => selectLanguage(lang)}
            >
              {`${lang.name}${lang.isDefault ? ' (default)' : ''}`}
              {lang.id === displayedLanguage.id && getIcon('baselineCheck', {})}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LocalizationSelector;
