import React, { useCallback, useMemo } from 'react';
import './ExperienceTile.scss';
import MenuIcon from 'assets/icons/menu-icon.svg';
import { FlightOverflowMenu } from '@flybits/design-system';
import { DropdownOptions } from 'interface/shared/dropdown.interface';
import useConfirmModal from 'hooks/useConfirmModal';
import { ConfirmationDialogProps, ConfirmationModalTypes } from 'components/Shared/shared.types';
import { ReactComponent as IconDeleteChart } from 'assets/icons/icon-delete-chart.svg';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  title: string;
  description?: string;
  graph: JSX.Element | null;
}

// CLASSES
const CLASS_MAIN = 'experience-tile';
const CLASSES = {
  BODY: `${CLASS_MAIN}__body`,
  HEADER: `${CLASS_MAIN}__header`,
  GRAPH: `${CLASS_MAIN}__graph`,
  CONFIRMATION_MODAL: `${CLASS_MAIN}__confirmation-modal`,
};

const HEADER = {
  TOPBAR: `${CLASSES.HEADER}__topbar`,
  TITLE: `${CLASSES.HEADER}__title`,
  MENU: `${CLASSES.HEADER}__menu`,
  MENU_ICON: `${CLASSES.HEADER}__menu-icon`,
  DESCRIPTION: `${CLASSES.HEADER}__description`,
};

const confirmationDialogProps: ConfirmationDialogProps = {
  theme: ConfirmationModalTypes.DELETE,
  className: CLASSES.CONFIRMATION_MODAL,
  icon: <IconDeleteChart />,
  title: 'Are you sure you want to delete this chart?',
  description: 'Deleting this chart will remove it from your dashboard. ',
  primaryAction: { value: 'Delete chart' },
  secondaryAction: { value: 'Cancel' },
};

function ExperienceTile({ title = '', description = '', graph }: Props) {
  const [RemoveTileConfirmModal, showRemoveTileConfirmModal] = useConfirmModal(confirmationDialogProps);
  const handleRemoveTile = useCallback(async () => {
    if (await showRemoveTileConfirmModal()) {
      console.log(`Graph ${title} removed`);
    }
  }, [showRemoveTileConfirmModal, title]);

  const contextMenuOptions: Array<Array<DropdownOptions>> = useMemo(
    () => [
      [
        {
          key: 1,
          name: 'Edit Graph',
          disabled: false,
          onClick: () => null,
        },
        {
          key: 2,
          name: 'Export CSV',
          disabled: false,
          onClick: () => null,
        },
        {
          key: 3,
          name: 'Remove',
          disabled: false,
          onClick: handleRemoveTile,
        },
      ],
    ],
    [handleRemoveTile],
  );

  return (
    <main className={CLASSES.BODY}>
      <div className={CLASSES.HEADER}>
        <div className={HEADER.TOPBAR}>
          <div className={HEADER.TITLE}>{title}</div>
          <div className={HEADER.MENU}>
            <img className={HEADER.MENU_ICON} alt="graph-menu" src={MenuIcon} />
            <FlightOverflowMenu
              direction={'bottom'}
              isOpenRight={false}
              disabled={false}
              optionGroups={contextMenuOptions}
            />
          </div>
        </div>
        <div className={HEADER.DESCRIPTION}>{description}</div>
      </div>
      <div className={CLASSES.GRAPH}>{graph}</div>
      <RemoveTileConfirmModal />
    </main>
  );
}

export default ExperienceTile;
