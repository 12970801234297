import { Content, ContentPrototype } from 'interface/content/content.interface';
import { Dependencies, VariablesArray } from 'interface/common.interface';
import { PushInstancePayload, PushTemplatePayload } from 'interface/push/push.interface';
import { WebhookInstancePayload, WebhookTemplatePayload } from 'interface/webhook/webhook.interface';

import { Audience } from 'interface/rule/rule.interface';
import { CONTENT_ACTION_TYPES } from 'store/content/content.type';
import { PUSH_ACTION_TYPES } from 'store/push/push.type';
import { WEBHOOK_ACTION_TYPES } from 'store/webhook/webhook.type';

// the fusion of template and instance makes us jump through hurdles for id
// once the old canvas is purged, death to the merged type
export type Experience = ExperienceTemplate & ExperienceInstance;

// template received from back-end
export interface ExperienceTemplate {
  audienceOptimizationEligible?: boolean;
  categories?: string[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  constraints?: {};
  createdAt?: number;
  deletedAt?: number;
  desc?: string;
  id: string;
  imageUrl?: string;
  labels?: string[];
  metadata?: {
    templateType?: string;
    stepMetadata?: string;
    filesUUID?: string;
  };
  name?: string;
  steps: TemplateStep[];
  updatedAt?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: any;
}

// instance payload used by UI
export interface ExperienceInstance {
  activatedAt?: number;
  audienceOptimization?: AudienceOptimization;
  createdAt?: number;
  deactivatedAt?: number;
  deletedAt?: number;
  desc?: string;
  id?: string;
  imageUrl?: string;
  isApproved?: boolean;
  labels?: string[];
  metadata?: {
    flowName?: string;
    iconUrl?: string;
    templateType?: string;
    stepMetadata?: string;
    filesUUID?: string;
    templateName?: string;
  };
  name?: string;
  parentEntityId?: string;
  parentEntityType?: string;
  schedule?: ExperienceSchedule;
  status?: string;
  steps: InstanceStep[];
  templateId?: string;
  tenantId?: string;
  updatedAt?: number;
  folderId?: string; //Future
  folderFullPath?: string; //Future
  showError?: boolean; //UI
  incompleteCount?: number; //UI
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: any;
}

export type Step = TemplateStep & InstanceStep & Partial<BranchStep>;

type BranchStep = {
  id: string;
  children: string[];
  type: string;
};

export type StepConstraints = {
  // TODO: confirm with backend the full list
  audienceConstraints: {
    syncStartScheduleWithRuleBody: boolean;
  };
};

export interface TemplateStep {
  type?: string; // TODO: confirm with backend the full list
  audience: Audience;
  actions: TemplateAction[];
  constraints?: StepConstraints;
}

export interface InstanceStep {
  name?: string;
  desc?: string;
  iconUrl?: string;
  isLocked?: boolean;
  audience: Audience;
  actions: InstanceAction[];
  experience?: {
    experienceId?: string;
  };
  hasError?: boolean; //UI
  id?: string; //UI
  groups?: Array<{ id: string; status: string }>; //UI
}

export type Action = TemplateAction & InstanceAction;

const ContentActions = [
  CONTENT_ACTION_TYPES.BLUEPRINT,
  CONTENT_ACTION_TYPES.GROUPING,
  CONTENT_ACTION_TYPES.INSTANCE,
] as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isContentAction = (x: any): x is ContentAction => ContentActions.includes(x.actionType);

export type PushAction = TemplatePushAction & InstancePushAction;

export type ContentAction = TemplateContentAction & InstanceContentAction;

export type TemplateAction = TemplatePushAction | TemplateContentAction | TemplateWebhookAction;

export type InstanceAction = InstancePushAction | InstanceContentAction | InstanceWebhookAction;

export type WebhookAction = TemplateWebhookAction | InstanceWebhookAction;

export interface TemplatePushAction extends TemplateBaseAction {
  actionType: PUSH_ACTION_TYPES;
  isOptional?: boolean;
  payload?: PushTemplatePayload;
}

export interface TemplateContentAction extends TemplateBaseAction {
  actionType: CONTENT_ACTION_TYPES;
  isOptional?: boolean;
  payload?: ContentPrototype;
}

export interface InstancePushAction extends InstanceBaseAction {
  actionType: PUSH_ACTION_TYPES;
  isOptional?: boolean;
  payload?: PushInstancePayload;
}

export interface InstanceContentAction extends InstanceBaseAction {
  actionType: CONTENT_ACTION_TYPES;
  isOptional?: boolean;
  payload?: Content;
}

export interface TemplateWebhookAction extends TemplateBaseAction {
  actionType: WEBHOOK_ACTION_TYPES;
  isOptional?: boolean;
  payload?: WebhookTemplatePayload;
}

export interface InstanceWebhookAction extends InstanceBaseAction {
  actionType: WEBHOOK_ACTION_TYPES;
  isOptional?: boolean;
  payload?: WebhookInstancePayload;
}

interface TemplateBaseAction extends BaseAction {
  id?: string;
  variables?: VariablesArray[];
}

interface InstanceBaseAction extends BaseAction {
  id?: string;
  hasError?: boolean; //UI
}

interface BaseAction {
  dependencies?: Dependencies;
  refId: string;
  templateId?: string;
}

export interface ExperienceSchedule {
  start?: number;
  end?: number;
  timezone?: string;
}

export interface AudienceOptimization {
  audienceOptimizationEligible: boolean;
  explorationBudget?: { populationSize?: number };
  automationBudget?: { cutOffPercentage?: number };
  journeyMode?: 'automation' | 'exploration' | 'manual';
  timestampLowerBound?: number;
  biasedFeatures?: string[];
  isEnabled?: boolean; //UI
  skipEngagementCalculation?: boolean;
}

export interface StepMetadata {
  name: string;
  desc: string;
  iconUrl?: string;
  isLocked?: boolean;
  contentNodes?: {
    [refId: string]: {
      name: string;
    };
  };
  webhookNodes?: {
    [refId: string]: {
      name: string;
    };
  };
}

export interface Status {
  key: string;
  name: string;
  isSelected: boolean;
}

export interface Label {
  key: string;
  name: string;
  isSelected?: boolean;
}

export interface Type {
  key: string;
  name: string;
  isSelected: boolean;
}
