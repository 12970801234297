import './JourneyEngagementTable.scss';
import React, { useMemo, useState } from 'react';
import { AdvancedDateRange, DateRange } from '../types';
import { FlightButton, FlightDateRangePicker, FlightTable, FlightTextInput, getIcon } from '@flybits/design-system';
import { epochToDateTimeString, intlNumberFormat } from 'helpers/common.helper';
import FilterByLabel from 'components/Analytics/FilterByLabel/FilterByLabel';
import { ENGAGEMENT_DATA_TYPES, JourneyEngagementData, LABEL_RULE } from 'components/Analytics/types';
import useDebounce from 'hooks/useDebounce';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import AnalyticsExperiencesAPI from 'services/api/analytics-experiences.api';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';
import { DATE_FORMAT_OPTIONS } from '../constants';
import { useThunkDispatch as useDispatch } from 'hooks/reduxHooks';
import DownloadEngagementData from 'pages/AnalyticsV2/AnalyticsDashboard/Engagement/DownloadEngagementData';
import useConfirmModal from 'hooks/useConfirmModal';
import { ConfirmationDialogProps, ConfirmationModalTypes } from 'components/Shared/shared.types';
import { ReactComponent as IconDownloadData } from 'assets/icons/icon-download-data.svg';

const MAIN_CLASS = 'journey-table';
const CLASSES = {
  HEADER: `${MAIN_CLASS}__header`,
  SEARCH: `${MAIN_CLASS}__header__search`,
  DOWNLOAD: `${MAIN_CLASS}__header__download`,
  DATE_RANGE: `${MAIN_CLASS}__date-range`,
  DATE_RANGE_DD: `${MAIN_CLASS}__date-range__dropdown`,
  CREATE_CHART: `${MAIN_CLASS}__create-chart`,
  EMPTY_DATA: `${MAIN_CLASS}__empty-data`,
  SPINNER: `${MAIN_CLASS}__spinner`,
  TABLE_WRAPPER: `${MAIN_CLASS}__table-wrapper`,
  TABLE_COMPLEX_NUMBER: `${MAIN_CLASS}__table__complex-number`,
};
const tableHeaders = [
  {
    name: <>key</>,
    key: 'key',
    isVisible: false,
    isSortable: false,
  },
  {
    name: <span>Name</span>,
    key: 'name',
    isVisible: true,
    isSortable: false,
    tooltip: 'The name of the experience',
  },
  {
    name: <span>Total impressions</span>,
    key: 'totalImpressions',
    isVisible: true,
    isSortable: true,
    tooltip: 'How many times a user has viewed a push notification or content in this experience',
  },
  {
    name: <span>Total / Unique engagement</span>,
    key: 'uniqueEngagements',
    isVisible: true,
    isSortable: true,
    tooltip:
      'Number of times a user has interacted with push notifications or content, including total and unique engagements',
  },
  {
    name: <span>Unique reach</span>,
    key: 'uniqueReach',
    isVisible: true,
    isSortable: true,
    tooltip: 'Unique users reached by push notifications or content in this experience',
  },
  {
    name: <span>Unique engagement rate</span>,
    key: 'uniqueEngagementRate',
    isVisible: true,
    isSortable: true,
    tooltip: 'The percentage of unique users who have engaged with push notifications or content in this experience',
  },
  {
    name: <span>Action</span>,
    key: 'action',
    // TODO: change visibility to true when Create Chart is implemented
    isVisible: false,
    isSortable: false,
  },
];
const fetchJourneyAnalytics = async (
  analyticsExperiencesApi: AnalyticsExperiencesAPI,
  page: number,
  perPage: number,
  dateRange: DateRange<Date>,
  search: string,
  labels: string,
  sortBy: string,
  sortDesc: string,
): Promise<JourneyEngagementData> => {
  const journeysData = await analyticsExperiencesApi.getJourneysEngagement({
    start: Math.trunc((dateRange[0]?.getTime() ?? new Date().setHours(0, 0, 0, 0)) / 1000),
    end: Math.trunc((dateRange[1]?.getTime() ?? new Date().setHours(23, 59, 59, 999)) / 1000),
    offset: (page - 1) * perPage,
    limit: perPage,
    search,
    labelsFormula: labels ? `(${labels})` : undefined,
    sortBy,
    sortDesc: sortDesc === 'desc',
  });
  return {
    pagination: journeysData.pagination,
    journeys: journeysData.rows,
  };
};

const confirmationDialogProps: ConfirmationDialogProps = {
  theme: ConfirmationModalTypes.PUBLISH,
  icon: <IconDownloadData />,
  title: 'Download journey table',
  description: 'Note that the table will be downloaded based on your current filters',
  primaryAction: {
    value: 'Download',
  },
  secondaryAction: {
    value: 'Cancel',
  },
};

const JourneyEngagementTable: React.FC = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const analyticsExperiencesApi = useMemo(() => new AnalyticsExperiencesAPI(), []);
  const startOfToday = new Date();
  startOfToday.setHours(0, 0, 0, 0);
  const endOfToday = new Date();
  endOfToday.setHours(23, 59, 59, 999);
  const [perPage, setPerPage] = useState(5);

  const [currentPage, setCurrentPage] = useState(1);
  const [dateRange, setDateRange] = useState<AdvancedDateRange>([startOfToday, new Date(), 0, 'day']);
  const [queryDateRange, setQueryDateRange] = useState<AdvancedDateRange>([startOfToday, endOfToday]);
  const [search, setSearch] = useState('');
  const [labels, setLabels] = useState<string[]>([]);
  const [labelRule, setLabelRule] = useState<LABEL_RULE>();
  const labelString = labelRule === LABEL_RULE.CONTAINS_ALL_OF ? labels.join(',') : labels.join(';');
  const [sortByColumn, setSortByColumn] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');
  const debouncedSearch = useDebounce(search, 300);
  const [journeyDataForCSV, setJourneyDataForCSV] = useState<Record<string, string>[]>([]);
  const [isAllJourneyDataLoading, setAllJourneyDataLoading] = useState(false);
  const [DownloadConfirmModal, showDownloadConfirmModal] = useConfirmModal(confirmationDialogProps);

  const { data, status, isFetching, isLoading } = useQuery({
    queryKey: [
      'journeys-engagement',
      currentPage,
      perPage,
      queryDateRange,
      debouncedSearch,
      labelString,
      sortByColumn,
      sortOrder,
    ],
    queryFn: () =>
      fetchJourneyAnalytics(
        analyticsExperiencesApi,
        currentPage,
        perPage,
        [queryDateRange[0], queryDateRange[1]],
        debouncedSearch,
        labelString,
        sortByColumn,
        sortOrder,
      ),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const handleSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setCurrentPage(1);
    setSearch(value);
  };
  const handleLabelSelect = (selectedLabels: string[], labelRule: LABEL_RULE) => {
    setLabels(selectedLabels);
    setLabelRule(labelRule);
  };
  const handleDateRangeChange = (newDate: AdvancedDateRange) => {
    setDateRange(newDate);
    const hasFullRange = newDate[0] !== null && newDate[1] !== null;

    if (hasFullRange) {
      const endOfDay = new Date(newDate[1] ?? new Date());
      if (newDate.length < 3 || (newDate.length >= 3 && newDate[2] === 0)) {
        // Known interval - no custom or single day
        endOfDay.setHours(23, 59, 59, 999);
      }

      setQueryDateRange([newDate[0], endOfDay]);
    }
  };
  const handleHeaderSort = (e: { key: string }) => {
    if (e.key !== sortByColumn) {
      setSortByColumn(e.key);
      setSortOrder('desc');
    } else {
      setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
    }
  };

  const generateCSVRecords = (data: JourneyEngagementData): Record<string, string>[] => {
    return data.journeys.map(
      ({
        journeyId,
        name,
        totalImpressions,
        uniqueReach,
        uniqueEngagements,
        uniqueEngagementRate,
        totalEngagements,
      }) => {
        return {
          'Journey ID': journeyId,
          'Journey Name': name,
          'Total Impressions': intlNumberFormat(totalImpressions),
          'Total Engagements': intlNumberFormat(totalEngagements),
          'Unique Engagements': intlNumberFormat(uniqueEngagements),
          'Unique Reach': intlNumberFormat(uniqueReach),
          'Unique Engagement Rate': intlNumberFormat(uniqueEngagementRate * 100) + '%',
        };
      },
    );
  };

  const handleClickDownload = async () => {
    try {
      if (await showDownloadConfirmModal()) {
        setAllJourneyDataLoading(true);
        const allData = await queryClient.fetchQuery({
          queryKey: ['all-journeys-engagement', queryDateRange, debouncedSearch, labelString, sortByColumn, sortOrder],
          queryFn: () =>
            fetchJourneyAnalytics(
              analyticsExperiencesApi,
              1,
              100, // Sending higher limit to BE to fetch all records --> the endpoint only supports between 0 and 100
              [queryDateRange[0], queryDateRange[1]],
              debouncedSearch,
              labelString,
              sortByColumn,
              sortOrder,
            ),
        });

        if (!allData.journeys?.length) {
          dispatch({
            type: 'SHOW_SNACKBAR',
            payload: {
              title: 'Info',
              content: 'No journey engagement data found',
              type: 'info',
            },
          });
        } else {
          setJourneyDataForCSV(generateCSVRecords(allData));
        }
      }
    } catch {
      dispatch({
        type: 'SHOW_SNACKBAR',
        payload: {
          title: 'Error',
          content: 'Something went wrong and could not download journey engagement data.',
          type: 'error',
        },
      });
    } finally {
      setAllJourneyDataLoading(false);
    }
  };

  return (
    <div className={MAIN_CLASS}>
      {status === 'success' && (
        <>
          <div className={CLASSES.HEADER}>
            <h2>Experiences</h2>
            <div className={CLASSES.DATE_RANGE}>
              {dateRange[2] === 0
                ? `${epochToDateTimeString(new Date().getTime(), 'en-US', DATE_FORMAT_OPTIONS)}`
                : `${epochToDateTimeString(dateRange[0] || 0, 'en-US', DATE_FORMAT_OPTIONS)} - ${epochToDateTimeString(
                    dateRange[1] || 0,
                    'en-US',
                    DATE_FORMAT_OPTIONS,
                  )}`}
            </div>
            <div>
              <FlightDateRangePicker
                className={CLASSES.DATE_RANGE_DD}
                value={dateRange}
                onChange={handleDateRangeChange}
                maxDate={new Date()}
                includeAllTime={false}
                includeToday
                isIncludeCustom
                replaceCustomRangeLabel={false}
              />
            </div>
            <FilterByLabel onLabelSelect={handleLabelSelect} />
            <div className={CLASSES.DOWNLOAD}>
              <DownloadEngagementData
                data={journeyDataForCSV}
                type={ENGAGEMENT_DATA_TYPES.JOURNEY}
                isDownloading={isAllJourneyDataLoading}
                onClickDownload={handleClickDownload}
              />
            </div>
          </div>
          <div className={CLASSES.SEARCH}>
            <FlightTextInput
              onChange={handleSearchChange}
              value={search}
              placeholderText="Search"
              iconInput="search"
              width="280px"
              hasClearIcon
            />
          </div>
          <div className={CLASSES.TABLE_WRAPPER}>
            <FlightTable
              tableHeaders={tableHeaders}
              tableData={data.journeys.map(
                ({
                  journeyId,
                  name,
                  totalImpressions,
                  uniqueReach,
                  uniqueEngagements,
                  uniqueEngagementRate,
                  totalEngagements,
                }) => {
                  return {
                    key: journeyId,
                    name: (
                      <>
                        <p>{name}</p>
                      </>
                    ),
                    totalImpressions: intlNumberFormat(totalImpressions),
                    uniqueEngagements: (
                      <div className={CLASSES.TABLE_COMPLEX_NUMBER}>
                        <span title={intlNumberFormat(totalEngagements)}>{intlNumberFormat(totalEngagements)}</span>/
                        <span title={intlNumberFormat(uniqueEngagements)}>{intlNumberFormat(uniqueEngagements)}</span>
                      </div>
                    ),
                    uniqueReach: intlNumberFormat(uniqueReach),
                    uniqueEngagementRate: intlNumberFormat(uniqueEngagementRate * 100) + '%',
                    action: (
                      <FlightButton
                        className={CLASSES.CREATE_CHART}
                        iconLeft={'addCircleOutline'}
                        size={'small'}
                        theme="link"
                        // eslint-disable-next-line no-console
                        onClick={() => console.log('TODO: create chart')}
                        label="Create chart"
                      />
                    ),
                  };
                },
              )}
              sortByKey={sortByColumn}
              sortOrder={sortOrder}
              handleHeaderSort={handleHeaderSort}
              hasPaginationBeforeTable={false}
              hasPaginationAfterTable={!!data?.journeys?.length}
              paginationProps={{
                totalPageNumber: Math.ceil(data.pagination.totalRecords / perPage) || 1,
                currentPageNumber: currentPage,
                rowsPerPageOptions: [5, 10, 15, 20],
                currentRowsPerPage: perPage,
                handlePageChange: (page: number) => setCurrentPage(page),
                handleRowsPerPageChange: (num: number) => setPerPage(num),
              }}
              isLoading={isFetching}
            />
            {isLoading && (
              <div className={CLASSES.SPINNER}>
                <LoadingIcon />
              </div>
            )}
            {!!!data?.journeys?.length && !isFetching && !isLoading && (
              <div className={CLASSES.EMPTY_DATA}>
                {getIcon('search', {})}
                No results found
              </div>
            )}
          </div>
        </>
      )}
      <DownloadConfirmModal />
    </div>
  );
};

export default JourneyEngagementTable;
